import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_cancelChangeOrder from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'
import consumer_t_od_trains_order_change_changePayCost from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-change_changePayCost'
import consumer_t_od_trains_order_change_changeConfirm from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-change_changeConfirm'
import moment from "moment";
import {awaitWrap, handlePromise} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data() {
        return {
            showMore: false,
            loading: false,
            orderNo: '',
            changeNo: '',
            orderDetail: {},
            changeDetail: {},
            oriPassengerList: [],
            priceInfo: {
                paymentFlag: 3, // 支付类型(1-需要补款 2-需要退款 3-不退不补)
            },
            payTime: 0,
            // payTimeText: '',
            payTimeFun: '',
        }
    },
    components: {TrainTimetable},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.changeNo = this.$route.query.changeNo;
        this.getChangeDetail();
    },
    deactivated() {
        clearInterval(this.payTimeFun);
    },
    destroyed() {
    },
    watch: {},
    computed: {
        payTimeText() {
            let payTime = this.payTime;
            if (payTime && payTime > 0) {
                let m = moment(payTime * 1000).format('mm:ss');
                return m
            } else {
                return '00:00'
            }
        },
    },
    filters: {
        filterDay(val) {
            if (!val) {
                return ''
            }
            let day = moment(val).format('MM月DD日');
            return day
        },
        filterWeek(val) {
            if (!val) {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        handleShowMore() {
            this.showMore = !this.showMore
        },
        getChangeDetail() {
            this.loading = true;
            const getDetail = consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo});
            const getChangeDetail = consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo});
            const getPriceType = consumer_t_od_trains_order_change_changePayCost({changeNo: this.changeNo});
            Promise.all(handlePromise([getDetail, getChangeDetail, getPriceType])).then(res => {
                this.loading = false;
                let passengerIdList = []
                if (res[1] && res[1].datas) {
                    this.changeDetail = res[1].datas;
                    this.changeDetail.changePassengerInfo.forEach(value => {
                        passengerIdList.push(value.userId)
                    })
                    if ([1, 2, 7].indexOf(this.changeDetail.changeOrderInfo.changeOrderStatus) >= 0) {
                        this.payTime = this.changeDetail.payLimitTime || 0;
                        this.setPayTime()
                    }

                    /*if (this.changeDetail.payLimitTime) {
                        let time = moment().valueOf();
                        time = time + (this.changeDetail.payLimitTime * 1000);
                        this.payTimeText = moment(time).format('HH:mm')
                    }*/
                }
                if (res[0] && res[0].datas) {
                    const datas = res[0].datas;
                    let passengerList = [];
                    datas.passengers.forEach(value => {
                        if (passengerIdList.indexOf(value.userId) >= 0) {
                            passengerList.push(value)
                        }
                    });
                    this.oriPassengerList = passengerList
                }
                if (res[2] && res[2].datas) {
                    this.priceInfo = res[2].datas
                }

                if (res[1] && res[1].datas) {
                    let setTime = setInterval(() => {
                        if (this.$refs['oriTrainTimeTable']) {
                            clearInterval(setTime);
                            this.$refs['oriTrainTimeTable'].init({
                                //出发站三字码
                                fromStationCode: this.changeDetail.originalTrainInfo.fromStationCode,
                                //到达站三字码
                                toStationCode: this.changeDetail.originalTrainInfo.toStationCode,
                                //车次号
                                trainCode: this.changeDetail.originalTrainInfo.trainCode,
                                //日期(2020-05-18)
                                trainDate: this.changeDetail.originalTrainInfo.startDate,
                            })
                        }
                    }, 200);
                    let setTime2 = setInterval(() => {
                        if (this.$refs['changeTrainTimeTable']) {
                            clearInterval(setTime2);
                            this.$refs['changeTrainTimeTable'].init({
                                //出发站三字码
                                fromStationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
                                //到达站三字码
                                toStationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
                                //车次号
                                trainCode: this.changeDetail.changeTrainInfo.changeTrainCode,
                                //日期(2020-05-18)
                                trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
                            })
                        }
                    }, 200)
                }
            }).catch(() => {
                this.loading = false
            })
        },
        setPayTime() {
            this.payTimeFun = setInterval(() => {
                this.payTime--;
                if (this.payTime <= 0) {
                    clearInterval(this.payTimeFun);
                    this.$alert('订单支付已超时，请重新操作', '温馨提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.back();
                        }
                    });
                }
            }, 1000)
        },
        back() {
            // this.$router.go(-1)
            this.$router.push({
                name: 'admin-my-order-train',
                query: {
                    type: 2
                }
            });
        },
        toCancelOrder() {
            if (this.changeDetail.changeOrderInfo.changeOrderStatus != 5) {
                this.loading = true;
                this.$message({
                    message: '该订单已取消改签',
                    type: 'warning'
                });

                setTimeout(() => {
                    this.loading = false;
                    // 跳转
                    this.$router.push({
                        name: 'admin-my-order-train-change-detail',
                        query: {
                            orderNo: this.orderNo,
                            changeNo: this.changeNo,
                            status: 3,
                            jumpType: 'list',
                        }
                    });
                },1000);
                return
            }

            this.$confirm('一天仅有3次取消订单的机会（包括自动取消），超过3次后当日将无法使用12306账号预订。', '确认要取消订单吗？', {
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                consumer_trains_order_cancelChangeOrder({changeNo: this.changeNo}).then(res => {
                    this.loading = false;
                    if (res.datas.ifIsSuccessful) {
                        this.$message({
                            type: "success",
                            message: '取消成功'
                        });
                        // 跳转
                        this.$router.push({
                            name: 'admin-my-order-train-change-detail',
                            query: {
                                orderNo: this.orderNo,
                                changeNo: this.changeNo,
                                status: 3,
                                jumpType: 'list',
                            }
                        });
                    }
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {

            });
        },
        toChange() {
            if (this.priceInfo.paymentFlag === 1) {
                let changeNo = this.changeNo;
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderNos: changeNo,
                        orderType: "2",// 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
                        pageType: 3,// 1是创建订单页，2是支付页，3是改签页
                    }
                });
            } else {
                this.loading = true;
                consumer_t_od_trains_order_change_changeConfirm({changeNo: this.changeNo}).then(res => {
                    this.loading = false;
                    this.$message({
                        type: "success",
                        message: '改签申请成功'
                    })
                    // 跳转
                    this.$router.push({
                        name: 'admin-my-order-train-change-detail',
                        query: {
                            orderNo: this.orderNo,
                            changeNo: this.changeNo,
                            status: 3,
                            jumpType: 'list'
                        }
                    })
                }).catch(() => {
                    this.loading = false;
                })
            }
        },
    }
}
